<template>
  <div class="card p-shadow-6">
    <h1>Assistências</h1>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog />
    <Dialog
      :visible.sync="statusMenu.show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="
        statusMenu.assist
          ? `Alterar Estado do Survey ${statusMenu.assist.pat} - ${statusMenu.assist.fullName}`
          : ''
      "
      :modal="true"
    >
      <Button
        v-if="statusMenu.assist && statusMenu.assist.toDo == 'Pendente'"
        label="Em Progresso"
        class="p-button-sm p-mr-2"
        v-tooltip="'Alterar Estado para Em Progresso'"
        :style="getGroupheaderColor({ toDo: 'Em Progresso' })"
        @click="changeStatus(statusMenu.assist, 'Em Progresso')"
      />
      <Button
        v-else-if="
          statusMenu.assist &&
          (statusMenu.assist.toDo == 'Em Progresso' ||
            statusMenu.assist.toDo == 'Agendado Sem Intervenção' ||
            statusMenu.assist.toDo == 'Agendado')
        "
        label="Pendente"
        class="p-button-sm p-mr-2"
        v-tooltip="'Alterar Estado para Em Progresso'"
        :style="getGroupheaderColor({ toDo: 'Pendente' })"
        @click="setPending(statusMenu.assist)"
      />
      <Button
        v-if="
          statusMenu.assist &&
          (statusMenu.assist.toDo == 'Pendente' ||
            statusMenu.assist.toDo == 'Em Progresso')
        "
        label="Realizado"
        class="p-button-sm p-mr-2"
        v-tooltip="'Alterar Estado para Realizado'"
        @click="changeStatus(statusMenu.assist, 'Realizado')"
      />
      <Button
        v-if="statusMenu.assist && statusMenu.assist.toDo == 'Agendado'"
        label="Alterar Data"
        class="p-button-sm p-mr-2"
        v-tooltip="'Alterar Data do Pat'"
        :style="getGroupheaderColor({ toDo: 'Agendado' })"
        @click="updateDate(statusMenu.assist)"
      />
      <Button
        v-if="
          statusMenu.assist &&
          statusMenu.assist.toDo == 'Agendado Sem Intervenção'
        "
        label="Marcar Intervenção"
        class="p-button-sm p-mr-2"
        v-tooltip="'Marcar Intervenção'"
        :style="getGroupheaderColor({ toDo: 'Agendado Sem Intervenção' })"
        @click="markIntervention(statusMenu.assist)"
      />
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="closeStatusMenu()"
        />
      </template>
    </Dialog>
    <W4Mstreak
      :states="streakStatus"
      v-on:W4MStreakChangeSelected="streakSelectedChange"
    />
    <RemovePendingNextIntervention
      :showToast="true"
      :show="assistRemovePendingNextIntervention.show"
      :patId="assistRemovePendingNextIntervention.patId"
      :patName="assistRemovePendingNextIntervention.patName"
      v-on:cancel="removePendingNextInterventionCancel"
      v-on:removePendingNextInterventionOk="removePendingNextInterventionOk"
    />
    <ChangeStatus
      :showToast="true"
      :show="assistChangeStatus.show"
      :patId="assistChangeStatus.patId"
      :patName="assistChangeStatus.patName"
      :newStatus="assistChangeStatus.newStatus"
      v-on:cancel="changeStatusCancel"
      v-on:changeStatusOk="changeStatusOk"
    />
    <UpdateDate
      :showToast="true"
      :show="assistUpdateDate.show"
      :patId="assistUpdateDate.patId"
      :patName="assistUpdateDate.patName"
      :patDate="assistUpdateDate.newStatus"
      v-on:cancel="updateDateCancel"
      v-on:setDateOk="updateDateOk"
    />
    <MarkIntervention
      :showToast="true"
      :show="assistMark.show"
      :patId="assistMark.patId"
      :patName="assistMark.patName"
      :patResume="assistMark.patResume"
      v-on:cancel="markInterventionCancel"
      v-on:marked="markInterventionOk"
    />
    <ChangeResponsible
      :showToast="true"
      :show="assistChangeResponsible.show"
      :patId="assistChangeResponsible.patId"
      :patName="assistChangeResponsible.patName"
      :currentTechnician="assistChangeResponsible.technicianId"
      v-on:cancel="changeResponsibleCancel"
      v-on:changeTechnicianResp="changeResponsibleOk"
    />
    <CloseAssist
      :showToast="true"
      :show="assistClose.show"
      :patId="assistClose.patId"
      :patName="assistClose.patName"
      v-on:cancel="closeCancel"
      v-on:closeAssistOk="closeOk"
    />
    <SetPending
      :showToast="true"
      :show="assistSetPending.show"
      :patId="assistSetPending.patId"
      :patName="assistSetPending.patName"
      v-on:cancel="setPendingCancel"
      v-on:setPendingOk="setPendingOk"
    />
    <div style="height: 65vh">
      <DataTable
        :value="patsList"
        :filters.sync="filters"
        filterDisplay="row"
        :loading="loading"
        class="p-datatable-sm"
        :sortOrder="1"
        sortField="streakIndex"
        responsiveLayout="scroll"
        rowGroupMode="subheader"
        removableSort
        groupRowsBy="toDo"
        :expandableRowGroups="true"
        :expandedRowGroups.sync="expandedRowGroups"
        @rowgroup-expand="rowExpand"
        sortMode="single"
        :scrollable="true"
        scrollHeight="flex"
      >
        <template #header>
          <div class="table-header">
            <Button
              icon="pi pi-sync"
              class="p-button-sm"
              label="Mostrar todas as assistências"
              @click="showAllAssists()"
            />
          </div>
        </template>
        <template #empty>
          Nada a mostrar. Selecione no menu em cima o que pretende visualizar.
        </template>
        <template #loading> A carregar dados. Por favor Aguarde... </template>
        <template #groupheader="slotProps">
          <div
            class="myGroupHeader"
            :style="getGroupheaderColor(slotProps.data)"
          >
            {{ slotProps.data.toDo }}
          </div>
        </template>
        <Column field="pat" header="Pat" filterField="pat">
          <template #body="slotProps">
            <span
              class="clickable-item"
              v-tooltip="'Ver Pat'"
              @click="viewPatInfo(slotProps.data.pat)"
              >{{ slotProps.data.pat }}</span
            >
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column header="Estado">
          <template #body="slotProps">
            <i
              class="pi pi-bookmark-fill streak-icon"
              :style="getIconColor(slotProps.data)"
            ></i>
            <span
              v-if="slotProps.data.toDo == 'Pendente Próxima Intervenção'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Remover Pendente Próxima Intervenção'"
              @click="removePendingNextIntervention(slotProps.data)"
              >{{ slotProps.data.toDo }}</span
            >
            <span
              v-else-if="
                slotProps.data.toDo == 'Pendente' ||
                slotProps.data.toDo == 'Em Progresso' ||
                slotProps.data.toDo == 'Agendado' ||
                slotProps.data.toDo == 'Agendado Sem Intervenção'
              "
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Alterar Estado'"
              @click="showStatusMenu(slotProps.data)"
              >{{ slotProps.data.toDo }}</span
            >
            <span
              v-else-if="slotProps.data.toDo == 'Concluido Por Fechar'"
              :id="'multi-status-' + slotProps.data.id"
              class="clickable-item"
              v-tooltip="'Fechar Assistência'"
              @click="close(slotProps.data)"
              >{{ slotProps.data.toDo }}</span
            >
            <span v-else>{{ slotProps.data.toDo }}</span>
          </template>
        </Column>
        <Column field="fullName" header="Nome" filterField="fullName">
          <template #body="slotProps">
            {{ slotProps.data.fullName }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="resume" header="Resumo" filterField="resume">
          <template #body="slotProps">
            {{ slotProps.data.resume }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column field="technician" header="Téc. Resp." filterField="technician">
          <template #body="slotProps">
            <span
              v-if="
                loggedUser.isEsa ||
                loggedUser.isAdmin ||
                loggedUser.id == slotProps.data.technicianId
              "
              class="clickable-item"
              v-tooltip="'Alterar Técnico Responsável'"
              @click="changeResposible(slotProps.data)"
              >{{
                slotProps.data.technician == ""
                  ? "_______"
                  : slotProps.data.technician
              }}</span
            >
            <span v-else>{{ slotProps.data.technician }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <InputText
              type="text"
              class="p-column-filter"
              v-model="filterModel.value"
              @input="filterCallback()"
            />
          </template>
        </Column>
        <Column
          field="office"
          header="Zona"
          filterField="office"
          :headerStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :filterHeaderStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
          :bodyStyle="{ 'min-width': '8.5%', 'max-width': '8.5%' }"
        >
          <template #body="slotProps">
            {{ slotProps.data.office }}
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @input="filterCallback()"
              :options="[
                { zone: 'Faro', zoneValue: 'Faro' },
                { zone: 'Lisboa', zoneValue: 'Lisboa' },
                { zone: 'Porto', zoneValue: 'Porto' },
                { zone: 'Sem Zona', zoneValue: '___' },
              ]"
              optionValue="zoneValue"
              optionLabel="zone"
              class="p-column-filter"
              :showClear="true"
              :style="{
                width: '80%',
              }"
            >
            </Dropdown>
          </template>
        </Column>
        <Column header="SLA">
          <template #body="slotProps">
            <span
              v-if="
                slotProps.data.slaHired != null &&
                slotProps.data.slaHired != '' &&
                slotProps.data.slaHired != 'N/A'
              "
              >{{ slotProps.data.slaHours }}/{{ slotProps.data.slaHired }}</span
            >
          </template>
        </Column>
        <Column
          field="patDate"
          header="Data"
          filterField="date_patDate"
          dataType="date"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.patDate }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <v-date-picker
              v-model="filterModel.value"
              @input="filterCallback()"
              :masks="{
                input: 'YYYY-MM-DD',
              }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  class="p-column-filter p-inputtext p-component"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </v-date-picker>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import W4Mstreak from "../components/W4Mstreak/W4Mstreak.vue";
import RemovePendingNextIntervention from "../components/assists/RemovePendingNextIntervention.vue";
import ChangeStatus from "../components/assists/ChangeStatus.vue";
import UpdateDate from "../components/assists/UpdateDate.vue";
import MarkIntervention from "../components/assists/MarkIntervention.vue";
import ChangeResponsible from "../components/assists/ChangeResponsible.vue";
import CloseAssist from "../components/assists/CloseAssist.vue";
import SetPending from "../components/assists/SetPending.vue";
import supportService from "../services/support.service";
import { FilterMatchMode } from "primevue/api/";

export default {
  name: "Assists",
  components: {
    W4Mstreak,
    RemovePendingNextIntervention,
    ChangeStatus,
    UpdateDate,
    MarkIntervention,
    ChangeResponsible,
    CloseAssist,
    SetPending
  },
  data() {
    return {
      streakStatus: [],
      patsList: [],
      expandedRowGroups: [],
      loading: false,
      filters: {
        pat: { value: null, matchMode: FilterMatchMode.EQUALS },
        office: { value: null, matchMode: FilterMatchMode.EQUALS },
        daysInProgress: { value: null, matchMode: FilterMatchMode.EQUALS },
        fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        resume: { value: null, matchMode: FilterMatchMode.CONTAINS },
        technician: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date_patDate: { value: null, matchMode: FilterMatchMode.DATE_IS },
      },
      statusMenu: { show: false, assist: null },
      assistRemovePendingNextIntervention: {
        show: false,
        patId: null,
        patName: null,
      },
      assistChangeStatus: {
        show: false,
        patId: null,
        patName: null,
        newStatus: null,
      },
      assistUpdateDate: {
        show: false,
        patId: null,
        patName: null,
        date: null,
      },
      assistMark: {
        show: false,
        patId: null,
        patName: null,
        patResume: null,
      },
      assistChangeResponsible: {
        show: false,
        patId: null,
        patName: null,
        technicianId: null,
      },
      assistClose: {
        show: false,
        patId: null,
        patName: null,
      },
      assistSetPending: {
        show: false,
        patId: null,
        patName: null,
      },
    };
  },
  computed: {
    loggedUser() {
      return this.$store.state.auth.user;
    },
  },
  async created() {
    if (this.$route.params.status) {
      this.filters.technician.value = this.loggedUser.username;
    }
    await this.initStreakStatus();
  },
  methods: {
    async initStreakStatus() {
      this.loading = true;
      let pendingNextIntervention =
        await supportService.countPendingNextIntervention();
      let pending = await supportService.countPending();
      let inProgress = await supportService.countInProgress();
      let scheduled = await supportService.countScheduled();
      let scheduledWithoutIntervention =
        await supportService.countScheduledWithoutIntervention();
      let slaOutOfDate = await supportService.countSlaOutOfDate();

      let activeStatus = this.$route.params.status
        ? this.$route.params.status
        : "";
      this.streakStatus = [
        {
          backgroudColor: "#FCC200",
          textColor: "#000",
          title: "Pendente Próxima Intervenção",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pendingNextIntervention.total
              : pendingNextIntervention.user,
          active: activeStatus === "Pendente Próxima Intervenção",
          data: [],
        },
        {
          backgroudColor: "#FFA100",
          textColor: "#000",
          title: "Pendente",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? pending.total
              : pending.user,
          active: activeStatus === "Pendente",
        },
        {
          backgroudColor: "#8E9BEF",
          textColor: "#FFF",
          title: "Em Progresso",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? inProgress.total
              : inProgress.user,
          active: activeStatus === "Em Progresso",
        },
        {
          backgroudColor: "#5F78DA",
          textColor: "#FFF",
          title: "Agendado",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? scheduled.total
              : scheduled.user,
          active: activeStatus === "Agendado",
        },
        {
          backgroudColor: "#4455BD",
          textColor: "#FFF",
          title: "Agendado Sem Intervenção",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? scheduledWithoutIntervention.total
              : scheduledWithoutIntervention.user,
          active: activeStatus === "Agendado Sem Intervenção",
        },
        {
          backgroudColor: "#ff0000",
          textColor: "#FFF",
          title: "SLA Ultrapassado",
          counter:
            this.loggedUser.isEsa || this.loggedUser.isAdmin
              ? slaOutOfDate.total
              : slaOutOfDate.user,
          active: activeStatus === "SLA Ultrapassado",
        },
      ];

      if (
        this.$store.state.auth.user.isAdmin ||
        this.$store.state.auth.user.isEsa
      ) {
        let completedByClose = await supportService.countCompletedByClose();
        this.streakStatus.push({
          backgroudColor: "#005B94",
          textColor: "#FFF",
          title: "Concluido Por Fechar",
          counter: completedByClose.total,
          active: activeStatus === "Concluido Por Fechar",
        });
      }

      if (activeStatus != "") {
        let activeStatusIndex = this.getStreakStatusIndex(activeStatus);
        if (activeStatusIndex >= 0) {
          let streakInfo = {
            currentStateIndex: activeStatusIndex,
            currentState: activeStatus,
          };
          this.streakSelectedChange(streakInfo);
        }
      }
      return (this.loading = false);
    },
    getGroupheaderColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.toDo
      );
      if (!statusInfo) {
        return {};
      }
      return {
        backgroundColor: statusInfo.backgroudColor,
        borderColor: statusInfo.backgroudColor,
        color: statusInfo.textColor,
      };
    },
    getIconColor(info) {
      let statusInfo = this.streakStatus.find(
        (status) => status.title == info.toDo
      );
      return { color: statusInfo.backgroudColor };
    },
    rowExpand(event) {
      let streakInfo = {
        currentStateIndex: this.getStreakStatusIndex(event.data),
        currentState: event.data,
      };
      return this.streakSelectedChange(streakInfo);
    },
    async showAllAssists() {
      this.patsList = [];
      for (const [index, status] of this.streakStatus.entries()) {
        status.loaded = false;
        await this.streakSelectedChange({
          currentStateIndex: index,
          currentState: status.title,
        });
      }
    },
    getStreakStatusIndex(status) {
      return this.streakStatus.findIndex(
        (streakItem) => streakItem.title == status
      );
    },
    streakStatusChange(from, to) {
      let statusFrom = this.streakStatus.find((status) => status.title == from);
      if (statusFrom) {
        statusFrom.counter = --statusFrom.counter;
      }
      if (to == "") {
        return;
      }
      let statusTo = this.streakStatus.find((status) => status.title == to);
      if (statusTo) {
        statusTo.counter = ++statusTo.counter;
      }
    },
    removeByStatus(status) {
      this.patsList.forEach((survey, index, object) => {
        if (survey.toDo === status) {
          object.splice(index, 1);
        }
      });
    },
    streakSelectedChange(selected) {
      if (
        this.streakStatus[selected.currentStateIndex].loaded != undefined &&
        this.streakStatus[selected.currentStateIndex].loaded == true
      ) {
        return;
      }
      this.streakStatus[selected.currentStateIndex].loaded = true;
      this.removeByStatus(selected.currentState);
      switch (selected.currentState) {
        case "Pendente Próxima Intervenção":
          this.getPendingNextIntervention(selected.currentStateIndex);
          break;
        case "Pendente":
          this.getPending(selected.currentStateIndex);
          break;
        case "Em Progresso":
          this.getInProgress(selected.currentStateIndex);
          break;
        case "Agendado":
          this.getScheduled(selected.currentStateIndex);
          break;
        case "Agendado Sem Intervenção":
          this.getScheduledWithoutIntervention(selected.currentStateIndex);
          break;
        case "SLA Ultrapassado":
          this.getSlaOutOfDate(selected.currentStateIndex);
          break;
        case "Concluido Por Fechar":
          this.getCompletedByClose(selected.currentStateIndex);
          break;
        default:
          return;
      }
      return this.expandedRowGroups.push(selected.currentState);
    },
    async getPendingNextIntervention(streakIndex) {
      this.loading = true;
      let pendingNextIntervention =
        await supportService.getPendingNextIntervention();
      pendingNextIntervention = pendingNextIntervention.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(pendingNextIntervention);
      return (this.loading = false);
    },
    async getPending(streakIndex) {
      this.loading = true;
      let pending = await supportService.getPending();
      pending = pending.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(pending);
      return (this.loading = false);
    },
    async getInProgress(streakIndex) {
      this.loading = true;
      let inProgress = await supportService.getInProgress();
      inProgress = inProgress.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(inProgress);
      return (this.loading = false);
    },
    async getScheduled(streakIndex) {
      this.loading = true;
      let scheduled = await supportService.getScheduled();
      scheduled = scheduled.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(scheduled);
      return (this.loading = false);
    },
    async getScheduledWithoutIntervention(streakIndex) {
      this.loading = true;
      let scheduledWithoutIntervention =
        await supportService.getScheduledWithoutIntervention();
      scheduledWithoutIntervention = scheduledWithoutIntervention.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(scheduledWithoutIntervention);
      return (this.loading = false);
    },
    async getSlaOutOfDate(streakIndex) {
      this.loading = true;
      let slaOutOfDate = await supportService.getSlaOutOfDate();
      slaOutOfDate = slaOutOfDate.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(slaOutOfDate);
      return (this.loading = false);
    },
    async getCompletedByClose(streakIndex) {
      this.loading = true;
      let completedByClose = await supportService.getCompletedByClose();
      completedByClose = completedByClose.map((v) => ({
        ...v,
        streakIndex: streakIndex,
        date_createAt: new Date(`${v.createAt} 00:00`),
        date_dateFirstContact: new Date(`${v.dateFirstContact} 00:00`),
        date_interventionDate: new Date(`${v.interventionDate}`),
        date_dateFirstIntervention: new Date(
          `${v.dateFirstIntervention} 00:00`
        ),
        date_patDate: new Date(`${v.patDate} 00:00`),
        fullName: `${v.name} | ${v.entity}`,
      }));
      this.patsList = this.patsList.concat(completedByClose);
      return (this.loading = false);
    },
    viewPatInfo(pat) {
      let route = this.$router.resolve({
        path: `/pat-details/${pat}`,
      });
      window.open(route.href, "_blank");
    },
    showStatusMenu(assist) {
      this.statusMenu.show = true;
      this.statusMenu.assist = assist;
    },
    closeStatusMenu() {
      this.statusMenu.show = false;
    },
    removePendingNextIntervention(assist) {
      return (this.assistRemovePendingNextIntervention = {
        show: true,
        patId: assist.pat,
        patName: assist.fullName,
        assist: assist,
      });
    },
    removePendingNextInterventionCancel() {
      return (this.assistRemovePendingNextIntervention = {
        show: false,
        patId: null,
        patName: null,
      });
    },
    removePendingNextInterventionOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.assistRemovePendingNextIntervention.assist.toDo,
          response.currentStatus
        );
        this.assistRemovePendingNextIntervention.assist.toDo =
          response.currentStatus;
        this.assistRemovePendingNextIntervention.assist.streakIndex =
          this.getStreakStatusIndex(
            this.assistRemovePendingNextIntervention.assist.toDo
          );
      }
      return this.removePendingNextInterventionCancel();
    },
    changeStatus(assist, newStatus) {
      this.closeStatusMenu();
      let status = null;
      if (newStatus == "Em Progresso") {
        status = "1. Em Progresso";
      } else if (newStatus == "Realizado") {
        status = "2. Realizado";
      } else if (newStatus == "Pendente") {
        status = "2. Pendente";
      }

      if (!status) {
        return this.$toast.add({
          severity: "error",
          summary: `O novo estado ${this.status} é inválido`,
          detail: "",
          life: 3000,
        });
      }
      return (this.assistChangeStatus = {
        show: true,
        patId: assist.pat,
        patName: assist.fullName,
        newStatus: status,
        assist: assist,
      });
    },
    changeStatusCancel() {
      return (this.assistChangeStatus = {
        show: false,
        patId: null,
        patName: null,
        newStatus: null,
      });
    },
    changeStatusOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.assistChangeStatus.assist.toDo,
          response.currentStatus
        );
        this.assistChangeStatus.assist.toDo = response.currentStatus;
        this.assistChangeStatus.assist.streakIndex = this.getStreakStatusIndex(
          this.assistChangeStatus.assist.toDo
        );
        if (response.currentStatus == "") {
          this.removeByStatus("");
        }
      }
      return this.changeStatusCancel();
    },
    updateDate(assist) {
      this.closeStatusMenu();
      return (this.assistUpdateDate = {
        show: true,
        patId: assist.pat,
        patName: assist.fullName,
        date: assist.patDate,
        assist: assist,
      });
    },
    updateDateCancel() {
      return (this.assistUpdateDate = {
        show: false,
        patId: null,
        patName: null,
        date: null,
      });
    },
    updateDateOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.assistUpdateDate.assist.toDo,
          response.currentStatus
        );
        this.assistUpdateDate.assist.toDo = response.currentStatus;
        this.assistUpdateDate.assist.patDate = response.currentDate;
        this.assistUpdateDate.assist.streakIndex = this.getStreakStatusIndex(
          this.assistUpdateDate.assist.toDo
        );
      }
      return this.updateDateCancel();
    },
    markIntervention(assist) {
      this.closeStatusMenu();
      return (this.assistMark = {
        show: true,
        patId: assist.pat,
        patName: assist.fullName,
        patResume: assist.resume,
        assist: assist,
      });
    },
    markInterventionCancel() {
      return (this.assistMark = {
        show: false,
        patId: null,
        patName: null,
        patResume: null,
      });
    },
    markInterventionOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.assistMark.assist.toDo,
          response.currentStatus
        );
        this.assistMark.assist.toDo = response.currentStatus;
        this.assistMark.assist.patDate = response.currentDate;
        this.assistMark.assist.streakIndex = this.getStreakStatusIndex(
          this.assistMark.assist.toDo
        );
      }
      return this.markInterventionCancel();
    },
    changeResposible(assist) {
      this.assistChangeResponsible.show = true;
      this.assistChangeResponsible.patId = assist.pat;
      this.assistChangeResponsible.patName = assist.fullName;
      this.assistChangeResponsible.technicianId = assist.technicianId;
      this.assistChangeResponsible.assist = assist;
    },
    changeResponsibleCancel() {
      this.assistChangeResponsible = {
        show: false,
        patId: null,
        patName: null,
        technicianId: null,
      };
    },
    changeResponsibleOk(response) {
      if (response.status == "ok") {
        this.assistChangeResponsible.assist.technician =
          response.newEmployeeUsername;
        this.assistChangeResponsible.assist.technicianId =
          response.newEmployeeId;
        this.assistChangeResponsible.assist.office =
          response.office;
      }
      return this.changeResponsibleCancel();
    },
    close(assist) {
      return (this.assistClose = {
        show: true,
        patId: assist.pat,
        patName: assist.fullName,
        assist: assist,
      });
    },
    closeCancel() {
      return (this.assistClose = {
        show: false,
        patId: null,
        patName: null,
      });
    },
    closeOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.assistClose.assist.toDo,
          response.currentStatus
        );
        this.assistClose.assist.toDo = response.currentStatus;
        this.assistClose.assist.streakIndex = this.getStreakStatusIndex(
          this.assistClose.assist.toDo
        );
        if (response.currentStatus == "") {
          this.removeByStatus("");
        }
      }
      return this.closeCancel();
    },
    setPending(assist) {
      this.closeStatusMenu();
      return (this.assistSetPending = {
        show: true,
        patId: assist.pat,
        patName: assist.fullName,
        assist: assist,
      });
    },
    setPendingCancel() {
      return (this.assistSetPending = {
        show: false,
        patId: null,
        patName: null,
      });
    },
    setPendingOk(response) {
      if (response.status == "ok") {
        this.streakStatusChange(
          this.assistSetPending.assist.toDo,
          response.currentStatus
        );
        this.assistSetPending.assist.toDo = response.currentStatus;
        this.assistSetPending.assist.streakIndex = this.getStreakStatusIndex(
          this.assistSetPending.assist.toDo
        );
      }
      return this.setPendingCancel();
    },
  },
};
</script>
<style>
.p-column-filter {
  width: 100%;
}

.p-tooltip {
  z-index: 5;
}
.p-datatable .p-datatable-loading-overlay {
  z-index: 5;
}

.myGroupHeader {
  display: inline-flex;
  height: 25px;
  padding: 0 15px;
  align-items: center;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 15px;
  cursor: default;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 2 !important;
}

.clickable-item {
  cursor: pointer;
  color: #000;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}

.streak-icon {
  transform: rotate(90deg);
}

.p-dialog .p-dialog-header-icons {
  display: none !important;
}
</style>
