<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :closable="false"
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Data do PAT de Assistência'"
      :modal="true"
      :contentStyle="{ overflow: 'hide' }"
    >
      <b>Pat: {{ patId }} - </b> {{ patName }} |
      <form
        name="setDateForm"
        class="p-col-12"
        @submit.prevent="patSetDate"
        style="min-height: 15vh"
      >
        <div class="p-d-flex p-jc-center">
          <v-date-picker
            v-model="date"
            :min-date="new Date()"
            :masks="{
              input: 'YYYY-MM-DD',
            }"
          >
          </v-date-picker>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="patSetDate"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import supportService from "../../services/support.service";
import { getOnlyDate } from "../../helpers/helpers";
export default {
  name: "AssistChangeDate",
  props: ["patId", "patName", "patDate", "showToast", "show"],
  data() {
    return {
      date: new Date(this.patDate),
    };
  },
  methods: {
    cancel() {
      return this.$emit("cancel");
    },
    patSetDate() {
      let bodyParams = {
        date: getOnlyDate(this.date),
      };
      return supportService.setDate(this.patId, bodyParams).then((response) => {
        if (!response) {
          if (this.showToast) {
            this.$toast.add({
              severity: "error",
              summary: "Erro",
              detail: "Ocorreu um erro ao ao alterar a data",
              life: 3000,
            });
          }
          return this.$emit("setDateOk", {
            status: "error",
            currentDate: this.patDate,
          });
        }

        if (this.showToast) {
          this.$toast.add({
            severity: "success",
            summary: "Data alterada",
            detail: "A data foi alterada com sucesso",
            life: 3000,
          });
        }
        return this.$emit("setDateOk", {
          status: "ok",
          currentDate: bodyParams.date,
          currentStatus: response.status,
        });
      });
    },
  },
};
</script>
