<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Alterar Técnico Responsável pela Assistência'"
      :modal="true"
      :closable="false"
    >
      <form
        name="AssistsChangeResponsible"
        class="p-col-12"
        @submit.prevent="saveNewTechnician"
        style="min-height: 15vh"
      >
        <p>
          <b>Pat {{ patId }} | {{ patName }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="responsibleUser"
                :options="listEmployees"
                :filter="true"
                :optionLabel="'username'"
                :optionValue="'employeeId'"
                v-model="current"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('responsibleUser') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext"
                >Selecionar Técnico Responsável pela Assistência</label
              >
            </span>
            <small
              v-if="errors.has('responsibleUser')"
              class="p-error"
              role="alert"
            >
              Funcionário é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="saveNewTechnician"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import supportService from "../../services/support.service";
import employeeService from "../../services/employee.service";
export default {
  name: "ChangeResponsible",
  props: ["showToast", "show", "currentTechnician", "patId", "patName"],
  watch: {
    currentTechnician() {
      this.$validator.pause();
      this.$validator.reset();
      this.current = this.currentTechnician;
    },
  },
  data() {
    return {
      current: this.currentTechnician,
      listEmployees: [],
    };
  },
  created() {
    this.getActiveEmployees();
  },
  methods: {
    getActiveEmployees() {
      return employeeService
        .getSupportActives()
        .then((response) => (this.listEmployees = response));
    },
    cancel() {
      return this.$emit("cancel");
    },
    saveNewTechnician() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return supportService
          .changeResponsible(this.patId, { employeeId: this.current })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: "Erro ao alterar o responsável pela assistência",
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("changeTechnicianResp", {
                status: "error",
                currenTechnican: null,
              });
            }

            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Responsável pela assistência alterado com sucesso`,
                detail: ``,
                life: 3000,
              });
            }
            return this.$emit("changeTechnicianResp", {
              status: "ok",
              newEmployeeId: response.newEmployeeId,
              newEmployeeUsername: response.newEmployeeUsername,
              office: response.office,
            });
          });
      });
    },
  },
};
</script>
