<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <ConfirmDialog group="dialogRemovePendingNextIntervention" />
  </div>
</template>
<script>
import supportService from "../../services/support.service";

export default {
  name: "RemovePendingNextIntervention",
  props: ["show", "showToast", "patId", "patName"],
  watch: {
    show() {
      if (this.show === true) {
        this.confirmRemovePendingNextIntervention();
      }
    },
  },
  methods: {
    confirmRemovePendingNextIntervention() {
      let title = `Remover Pendente para a Próxima Intervenção`;
      let message = `Tem a certeza que pretende remover o pendente da próxima intervenção de ${this.patId} - ${this.patName}?`;

      this.$confirm.require({
        key: "dialogRemovePendingNextIntervention",
        header: title,
        message: message,
        icon: "pi pi-question-circle",
        acceptLabel: "Sim",
        acceptIcon: "pi pi-check",
        acceptClass: "p-button-success p-button p-component",
        rejectLabel: "Não",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-danger p-button p-component",
        accept: () => {
          return this.removeThePending();
        },
        reject: () => {
          return this.$emit("cancel");
        },
      });
    },
    removeThePending() {
      return supportService
        .removePendingNextIntervention(this.patId)
        .then((response) => {
          if (!response) {
            if (this.showToast) {
              this.$toast.add({
                severity: "error",
                summary: `Erro ao Retirar o Pendente Próxima Intervenção`,
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("removePendingNextInterventionOk", {
              status: "error",
              currentStatus: {},
            });
          }
          if (this.showToast) {
            this.$toast.add({
              severity: "success",
              summary: `Retirado o Pendente Próxima Intervenção`,
              detail: "",
              life: 3000,
            });
          }
          return this.$emit("removePendingNextInterventionOk", {
            status: "ok",
            currentStatus: response.status,
          });
        });
    },
  },
};
</script>
