<template>
  <div>
    <Toast :style="{ width: $isMobile() ? '85vw' : '' }" />
    <Dialog
      :visible.sync="show"
      :style="{ width: $isMobile() ? '100vw' : '30vw' }"
      :header="'Fechar Assistência'"
      :modal="true"
      :closable="false"
    >
      <form
        name="CloseAssists"
        class="p-col-12"
        @submit.prevent="closeAssist"
        style="min-height: 15vh"
      >
        <p>
          <b>Pat {{ patId }} | {{ patName }}</b>
        </p>
        <div class="p-fluid p-formgrid p-grid" style="min-height: 50vh">
          <div class="p-field p-col-12 p-md-12 p-mt-4">
            <span class="p-float-label">
              <Dropdown
                name="responsibilitite"
                :options="respList"
                v-model="resp"
                v-validate="'required'"
                v-bind:class="[
                  { 'p-invalid': errors.has('responsibilitite') },
                  'form-control',
                ]"
              >
              </Dropdown>
              <label for="inputtext">Selecionar Responsabilidade</label>
            </span>
            <small
              v-if="errors.has('responsibilitite')"
              class="p-error"
              role="alert"
            >
              Responsabilidade é obrigatório
            </small>
          </div>
        </div>
      </form>
      <template #footer>
        <Button
          :label="'Guardar'"
          icon="pi pi-check"
          class="p-button-success"
          @click="closeAssist"
        />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-danger"
          @click="cancel"
        />
      </template>
    </Dialog>
  </div>
</template>
<script>
import supportService from "../../services/support.service";

export default {
  name: "CloseAssist",
  props: ["show", "showToast", "patId", "patName"],
  watch: {
    show() {
      if (this.show === true) {
        this.$validator.resume();
      } else {
        this.resp = null;
        this.$validator.pause();
        this.$validator.reset();
      }
    },
  },
  data() {
    return {
      resp: null,
      respList: ["Cliente", "W4M", "Operadora", "W4m/Operadora", "Parceiro"],
    };
  },
  methods: {
    cancel() {
      this.patId = null;
      this.resp = null;
      return this.$emit("cancel");
    },
    closeAssist() {
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }
        this.$validator.pause();
        this.$validator.reset();
        return supportService
          .closeAssist(this.patId, { responsibility: this.resp })
          .then((response) => {
            if (!response) {
              if (this.showToast) {
                this.$toast.add({
                  severity: "error",
                  summary: `Erro ao fechar a Assistência`,
                  detail: "",
                  life: 3000,
                });
              }
              return this.$emit("closeAssistOk", {
                status: "error",
                currentStatus: {},
              });
            }
            if (this.showToast) {
              this.$toast.add({
                severity: "success",
                summary: `Assistência Fechada`,
                detail: "",
                life: 3000,
              });
            }
            return this.$emit("closeAssistOk", {
              status: "ok",
              currentStatus: response.status,
            });
          });
      });
    },
  },
};
</script>
